import React, { useState , useRef } from 'react'
import InputWidget from './InputWidget'
import emailjs from '@emailjs/browser';
import LoadingSpinner from '../../LoadingSpinner';
import SnackBar from '../../snackbar/SnackBar';

const inputData= [
    {
        label : "Name",
        type: "text",
        name : "name",
        errorMsg : "Name should be atleast 3 -20 charecters without special charecters",
    },
    {
        label : "Gmail",
        type: "email",
        name : "gmail",
        errorMsg : "please enter a valid Email Id",
    },
    {
        label : "Phone No",
        name : "phoneNo",
        type: "text",
        errorMsg : "please enter 10 digit Mobile Number",
    },
    {
        label : "Message",
        name : "message",
        type: "text",
        errorMsg : "Message should be atleast 10 charecters",
    },

]

const patterns = {
    name : /^[A-Za-z0-9\s]{3,20}$/,
    gmail :  /^[a-zA-Z0-9_.+-]+@gmail.com$/,
    phoneNo : /^\d{10}$/,
    message : /^[\s\S]{10,200}$/ 
}

const EmailUs = ({setsentMsg}) => {

    const [info, setInfo] = useState({
        name : "",
        gmail : "",
        phoneNo : "",
        message : ""
    })

    const [valid, setValid] = useState({
        name : true,
        gmail : true,
        phoneNo : true,
        message : true
    })


    const [startSending, setstartSending] = useState(false);
    const formRef = useRef();
    const snackBarRef = useRef(null);

    
    const handleMail = async() => {
        setstartSending(true);
        try{
            await emailjs.sendForm("service_frbcmwa" , "template_jv39m3s" , formRef.current , "o3h_bYlrrQVvgkGB3" )
            setsentMsg(true);
        }catch(e){
            snackBarRef.current.show() 
            setstartSending(false);
        }

    } 

    const handleSubmit = (e) => {
        e.preventDefault();
        let validation = {};
        for(const [key ,value] of Object.entries(info)){
                validation[key] = patterns[key].test(value.trim());    
        }
        setValid(validation);
        const isAllValid = Object.values(validation).every(value => value === true );
        if(isAllValid){
            handleMail();
        }
    }

    const handleChange = (e) => {
        setInfo({...info , [e.target.name] : e.target.value })
        console.log(e.target.value);
    }
  return (
    <section className='contacts'>
        <SnackBar ref = {snackBarRef}  />

        <form  ref={formRef}  onSubmit={handleSubmit} className = "text-form" >
               <div>
                {inputData.map((e) =>{
                    console.log(e.name , "<--->" , valid[e.name] )
                 return   <InputWidget 
                    key={e.name} {...e} 
                    value={info[e.name]}  
                    handleChange = {handleChange} 
                    valid = {valid[e.name]}
                   /> 
                }
                )}
               </div>
               {
                startSending ? 
                <LoadingSpinner/>
                   :
                   <button >Submit</button>

               }
        </form>
    </section>
  )
}

export default EmailUs