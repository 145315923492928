import React from 'react'

const ContactButton = () => {
  return (
    <a className="contact-button" href='#contacts' >
        Contact Us
    </a>
  )
}

export default ContactButton