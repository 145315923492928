import React from 'react'
import "./footer.css"

const Footer = () => {
  return (
    <footer>
          Copyight &copy; 2023 Beast XD . All rights reserved
        </footer>
  )
}

export default Footer