import React , {useState} from 'react'
import "./services.css"
import ServiceWidget from './widgets/ServiceWidget'
import {SiFlutter} from "react-icons/si"
import {SiReact} from "react-icons/si"
import {SiNodedotjs } from "react-icons/si"
import {SiAmazonaws } from "react-icons/si"
import {SiGooglecloud } from "react-icons/si"
import {SiFirebase } from "react-icons/si"
import {SiTerraform } from "react-icons/si"
import {SiAwsamplify } from "react-icons/si" 

import {DiAndroid} from "react-icons/di"
import {BsApple} from "react-icons/bs"

import {CgBrowser} from "react-icons/cg"
import {TbCloudComputing} from "react-icons/tb"

import Lottie from "lottie-react"
import dev from "../../assets/lottie/development.json"


const devData = [
  {
    title :"APP  Development",
    icons : [<DiAndroid/> , <BsApple/> ],
    info : "We specialize in developing top-of-the-line mobile applications for both Android and iOS using the cutting-edge cross-platform framework, Flutter. Our use of Flutter enables us to deliver cost-effective and fast-paced development and deployment of your apps, outperforming traditional native app development. Let us bring your vision to life with exceptional mobile solutions.",
    softwares : [<SiFlutter/>] 
  },
  {
    title :"Web Development",
    icons : [<CgBrowser/> ],
    info : "we leverage the power of React and Node.js to deliver high-performance web solutions that drive your business forward. Our team of experts uses these versatile technologies to build custom web applications that are both user-friendly and scalable. With React's virtual DOM and Node.js's robust ecosystem, we are able to create seamless and fast-paced web solutions that meet your exact needs. Whether you're looking to modernize your online presence or create a new web platform from scratch, we've got you covered",
    softwares : [<SiReact/> , <SiNodedotjs/> ] 
  },
  {
    title: "Cloud Solutions" , 
    icons : [<TbCloudComputing/> ],
    info : " we offer complete end-to-end solutions for your technology needs. In addition to our expertise in Flutter and React, we provide seamless backend integration with leading cloud platforms including AWS, AWS Amplify, Google Cloud, Firebase, and we are well-versed in Terraform. Let us help you leverage the power of the cloud to drive your business forward.",
    softwares : [<SiAmazonaws/>,<SiAwsamplify/> , <SiGooglecloud/>, <SiFirebase/> , <SiTerraform/> , ] 
 }
]

const Services = () => {
  const [show, setshow] = useState("");
  return (
        <section className='service_container' id='services'>
          <div className="services_title">
             <h2>Our Services</h2>
             <h4>Beast XD is dedicated to your company's success. Our aim is to build your brand and online presence, not just deliver a software solution. Our extensive software services are tailored to meet your technology needs and drive business growth.</h4>
          </div>
          <div className="service-section">
            <div className="service-lottie">
              <Lottie animationData={dev} />
            </div>
            <div className="available_services">
              {
                devData.map((e) => {
                  return <ServiceWidget value = {e} state = {show} changeState= {setshow}  />
                }
                )
              }
            </div>
          </div>
          <h4>
             We go beyond just buiding applications, we also ensure they are always running smoothly and efficiently. Our maintenance services ensure that the applications we build for you, or even existing systems, are kept up to date and functioning optimally. With affordable maintenance costs, you can focus on your core business without worrying about the technicalities of software management .
          </h4>
        </section>
  )
}

export default Services